import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/car/bind/list', params);

// /web/house/label/update put
export const update = params => put('/web/car/bind/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/car/bind/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/car/bind/view/' + params.id, '');

//选择车型列表
export const getcarTypeList = params => get('/web/car/bind/type/list', params)