<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入车型名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="10"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.code"
                  placeholder="输入车辆编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.tbox"
                  placeholder="输入TBOX编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="background: #f59a23;color: #ffffff;border-color: #f59a23"
                icon="el-icon-upload2"
                @click="handleImport"
                class="marginLeft10 marginBottom10"
              >导入</el-button>
            </div>

          </div>
        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <!-- <el-button
          style="color: #D9001B"
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button> -->

      </template>
    </avue-crud>

    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import { list, deleteNew } from "@/api/car/bind";
import Import from "@/components/import";

export default {
  name: "index",
  components: {
    Import,
  },
  data() {
    return {
      searchData: {
        name: "",
        code: "",
        tbox: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "车辆绑定",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型名称",
            prop: "name",
          },
          {
            label: "车辆编号",
            prop: "code",
          },
          {
            label: "TBOX",
            prop: "tbox",
          },
        ],
      },

      importShow: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      importAction: "/api/web/car/bind/template/import",
      importProblem: "/api/web/car/bind/template/error/export",
      importDownloadLink: "/api/web/car/bind/template/export",
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        name: "",
        code: "",
        tbox: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 批量导入
    handleImport() {
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      console.log("e111", e);
      this.onLoad();
    },
    // 编辑优惠劵
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/car/bind/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
</style>